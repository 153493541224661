@import "antd/dist/antd.css";
* {
  box-sizing: border-box;
}

.reveal,
.reveal.active {
  transition: all 1s ease;
}

.layout {
  min-height: 100em;
  z-index: -2;
  background: linear-gradient(160deg, #0b1117 0, #0033a0 100%) no-repeat
    padding-box;
}

.reveal {
  transform: translateY(100px);
  opacity: 0;
}

.reveal.active {
  transform: translateY(0);
}

.frame #atc-logo,
.frame h1 {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.938));
}

.frame h1,
.recent h2,
.video-content h4 {
  text-align: left;
  text-transform: uppercase;
}

.atc-content {
  justify-content: center;
  align-items: center;
}

.lecture-page {
  display: flex;
}

.photo-carousel {
  height: 0;
}

.photo-carousel img {
  height: 900px;
  margin-top: 0;
  object-fit: cover;
  object-position: 50% 50%;
  opacity: 0.9;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  -ms-transition: all 1s ease-out;
  transition: all 0.5s ease-out;
}

.photo-carousel img:hover {
  object-position: 50% 50%;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 0.5s ease-in;
}

.frame {
  height: 650px;
  border: 2px solid #ffffffab;
  border-radius: 50px;
  opacity: 0.9999;
  width: auto;
  display: block;
  z-index: 2;
  transition: 1s;
  margin: -30px -20px 160px;
}

.frame #atc-logo {
  float: left;
  position: relative;
  width: 300px;
  margin: 0 20px;
  top: 320px;
  transition: 1s;
}

.frame h1 {
  padding: 395px 80px;
  font: normal normal normal 98px/178px Abolition;
  letter-spacing: 0;
  z-index: 6;
  transition: 1s;
}

.recent h2 {
  font: normal normal normal 25px/16px Abolition;
  letter-spacing: 2px;
  text-shadow: 2px 2px 10px #0a152d;
  opacity: 0.78;
}

.video-content h3,
.video-content h4 {
  opacity: 0.4;
  text-overflow: ellipsis;
  overflow: hidden;
}

.divider {
  background: linear-gradient(-90deg, #ffffffd0 0%, transparent 100%) no-repeat
    padding-box;
  height: 3px;
  border-radius: 50px;
  margin-bottom: 20px;
  float: right;
}

.video-content .list-thumbnail {
  padding-right: 10px;
}

.blank-thumbnail {
  background: linear-gradient(210deg, #0094e9b7 0, #0033a0 40%, #0b1117a9 100%)
    no-repeat padding-box;
  width: 200px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-content h2 {
  font: normal normal normal 16px/25px Open Sans;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5em;
  max-height: 3em;
}

.video-content h3 {
  font: normal normal normal 13px/14px Open Sans;
  letter-spacing: 0;
  line-height: 1.5em;
  max-height: 3em;
}

.video-content h4 {
  font: bold normal normal 12px/16px Open Sans;
  letter-spacing: 0.2px;
}

.no-results h4 {
  padding-top: 80px;
  font: normal normal normal 26px/28px Abolition;
  text-align: center;
  letter-spacing: 1px;
  opacity: 0.5;
}

.video-card {
  background-color: transparent;
  border-radius: 5px;
  min-height: 150px;
}

.video-card .card-content {
  padding: 0 0 0 12px;
  margin-left: -8px;
  box-shadow: inset 0 0 80px transparent;
}

.video-card .duration {
  position: relative;
  bottom: 34px;
  right: 10px;
  float: right;
  background: #191919;
  border-radius: 5px;
}

.video-card h5,
.video-content .list-thumbnail h5 {
  font: normal normal normal 13px/14px Open Sans;
  padding: 4px 5px 0;
}

.play {
  width: 1em;
  font-size: 2.6em;
  opacity: 0.8;
  position: absolute;
  background-color: transparent;
  top: 10px;
  left: 20px;
  transition: 0.3s;
}

.video-card:hover .play {
  filter: drop-shadow(1px 1px 10px #00194e);
  font-size: 3.8em;
  transition: 0.3s;
}

.video-card:hover {
  max-height: 10px;
  transition: 0.5s;
}

.ant-card-cover img {
  border-radius: 5px;
  height: 200px;
  object-fit: cover;
  object-position: 50% 10%;
  -o-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: brightness(70%);
  z-index: 2;
  transition: 0.5s;
}

.video-card:hover img {
  filter: brightness(100%);
  transition: all 0.5s;
}

.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: none;
}

.responsive {
  padding: 15px 6px;
  margin-top: 50px;
}

.content-lists {
  padding: 15px 6px;
  margin-top: 80px;
  margin-bottom: 30px;
  width: auto;
  max-width: 1400px;
  position: relative;
  top: -15px;
  transition: 2s;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.ant-list,
.ant-list img,
.ant-list:hover,
.ant-list:hover img {
  transition: all 0.5s;
}

.container {
  background: linear-gradient(150deg, #0093e9 0, #0033a0 20%, #0b1117 100%)
    no-repeat padding-box;
  border-radius: 30px;
  opacity: 0.96;
  margin-top: 120px;
  margin-bottom: 50px;
  min-width: 40px;
  width: 1000px;
  height: auto;
  padding: 80px 80px 70px;
  justify-content: center;
  align-content: center;
}

.container-content h1 {
  padding-bottom: 20px;
  font: normal normal normal 35px/40px Abolition;
  letter-spacing: 1px;
  color: #fff;
}

.container-content h5 {
  float: right;
  padding-top: 3px;
  font: normal normal normal 26px/32px Abolition;
  text-align: center;
  letter-spacing: 1px;
  color: #fff;
  opacity: 0.5;
}

.list-content h3 {
  padding: 0;
  margin: 0;
  position: relative;
}

.list-content h2 {
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
}

.ant-list {
  left: -10px;
  padding: 10px;
  margin: 0;
  border-radius: 10px;
}

.ant-list:hover {
  background: #0c183373;
}

.ant-list img {
  border-radius: 5px;
  width: 200px;
  height: 120px;
  object-fit: cover;
  object-position: 50% 50%;
}

.ant-pagination {
  margin-top: 40px;
  position: relative;
  float: right;
}

.ant-pagination-item {
  background-color: #769fff50;
  font: normal normal normal 20px/28px Abolition;
}

.ant-pagination-item a {
  transition: all 0.3s;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  background-color: #5267977e;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  background-color: #8dafff2a;
  color: #0f15227e;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
  color: #8dafff7e;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(255, 255, 255, 0.25);
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  background-color: #4051797e;
}

.ant-select-dropdown {
  position: sticky;
}

.ant-select-item {
  font: normal normal normal 18px/28px Abolition;
  color: rgba(255, 255, 255, 0.986);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #5d6c8f;
}

.ant-pagination-options,
.ant-pagination-options-size-changer.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font: normal normal normal 20px/28px Abolition;
  letter-spacing: 0.5px;
  background-color: #3745667e;
  color: rgba(255, 255, 255, 0.986);
}

.ant-pagination-item-active {
  background: #8dafff7e;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #363636;
}

.empty {
  font: normal normal normal 32px/42px Abolition;
  opacity: 0.2;
}

.video-box {
  height: auto;
  padding: 50px;
  background: linear-gradient(30deg, #0093e9 0, #0033a0 25%, #0b1117 100%)
    no-repeat padding-box;
  width: auto;
  width: 1300px;
  margin-top: 100px;
  margin-bottom: 80px;
  border-radius: 50px;
}

.video-window {
  margin: 0 auto;
  text-align: center;
}

iframe {
  margin: 0 auto;
  height: 700px;
}

.lecture-details h1.ant-typography {
  margin-top: 0.5em;
  font: normal normal bold 30px/40px Open Sans;
}

.lecture-details h2.ant-typography {
  padding-top: 20px;
  padding-left: 5px;
  font: normal normal bold 25px/25px Open Sans;
}

.lecture-details h3.ant-typography {
  padding-top: 40px;
  text-transform: uppercase;
  font: bold normal normal 14px/14px Open Sans;
  letter-spacing: 0.2px;
  opacity: 0.6;
}

.lecture-details h4.ant-typography {
  font: normal normal normal 16px/16px Open Sans;
  opacity: 0.6;
}

.lecture-details p {
  font: normal normal normal 14px/30px Open Sans;
  letter-spacing: 0;
  word-break: break-word;
}

.lecture-details a {
  font: normal normal normal 16px/16px Open Sans;
  opacity: 0.6;
}

.details #date:not(:last-child)::after,
.details #event:not(:last-child)::after {
  content: "•";
  padding: 0 10px;
  visibility: visible;
}

.files .ant-list-item,
.files .ant-list-item a {
  font: normal normal normal 15px/15px Open Sans;
}

.lecture-details .details {
  flex-direction: row;
  padding: 5px 0 40px;
}

.lecture-details .ant-typography + h4.ant-typography {
  margin-top: 0;
}

.files .download-title {
  align-items: center;
  justify-content: start;
}

.files .ant-list {
  left: 30px;
  padding-bottom: 20px;
}

.files .ant-list-item a {
  opacity: 0.8;
  transition: 0.5s;
}

.files .ant-list-item a:hover {
  transition: 0.5s;
}

.files .ant-list:hover {
  background: 0 0;
}

#about-us-content,
.member-card,
.member-card:hover {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  -o-appearance: auto;
  -ms-appearance: auto;
}

.atc-title h2,
.atc-title h3 {
  text-align: center;
  letter-spacing: 0;
  color: #dbe2e9;
  text-transform: uppercase;
}

.member-card {
  top: 35px;
  background: linear-gradient(160deg, #0094e9d7 0, #0033a0 40%, #0b1117 100%)
    no-repeat padding-box;
  align-items: center;
  border-radius: 30px;
  width: 310px;
  padding-bottom: 40px;
  transition: all 0.5s;
}

.member-card .ant-avatar {
  margin-top: 10px;
  align-content: center;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  object-position: 50% 50%;
  border: 1px solid #fff;
  z-index: 2;
}

.member-card .grid-style {
  height: 150px;
  position: relative;
  bottom: 70px;
  box-shadow: inset 0 -80px 80px transparent;
  border-radius: 30px;
}

.member-card .member-content h2 {
  text-align: center;
  font: bold normal normal 16px/18px Open Sans;
  padding-top: 30px;
}

.member-card .member-content h3 {
  font: normal normal normal 20px/25px Open Sans;
  float: right;
  opacity: 0.7;
  padding-top: 70px;
  margin-left: -50px;
  right: 50px;
  z-index: -2;
}

.member-card .member-content p {
  text-align: center;
  font: normal normal normal 14px/18px Open Sans;
  z-index: 5;
}

.member-card:hover {
  top: 23px;
  transition: all 0.5s;
}

#about-us-content {
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.atc-title {
  padding-top: 30px;
}

.atc-title h2 {
  font: normal normal normal 52px/94px Abolition;
}

.atc-title h3 {
  margin-top: 50px;
  font: normal normal normal 32px/52px Abolition;
}

.our-mission h4 {
  font: normal normal normal 16px/22px Open Sans;
  color: #fff;
  padding: 40px;
}

#atc-logo img,
.ant-card-cover img,
.ant-list,
.divider,
.list-content h2,
.list-content h3,
.member-card .grid-style,
.photo-carousel img,
.responsive,
.video-card .card-content,
iframe {
  width: 100%;
}

.files .download-title,
.lecture-details .details,
.list-content h3,
.member-card .ant-avatar,
.photo-carousel img,
.video-content {
  display: flex;
}

.container-content h1,
.files .ant-list-item a:hover,
.frame #atc-logo,
.frame h1,
.layout,
.lecture-details h1.ant-typography,
.lecture-details h2.ant-typography,
.lecture-details p,
.photo-carousel img:hover,
.reveal.active,
.video-box,
.video-card:hover .play,
.video-content .list-thumbnail h5,
.video-content h2 {
  opacity: 1;
}

.ant-select-dropdown,
.ant-select-item {
  background-color: #405179;
}

.atc-title h2,
.atc-title h3,
.container,
.content-lists,
.empty,
.member-card .ant-avatar,
.photo-carousel img,
.video-box {
  margin-left: auto;
  margin-right: auto;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-item-active,
.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-options,
.ant-pagination-options-size-changer.ant-select,
.ant-pagination-prev,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.member-card,
.play,
.video-card,
iframe {
  border: none;
}

.ant-pagination-item a,
.ant-pagination-item-active a,
.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a,
.ant-pagination-item-ellipsis,
.ant-pagination-item:hover a,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-next button,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-next:hover button,
.ant-pagination-prev button,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-prev:hover button,
.ant-select-arrow,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state,
.anticon-double-right .ant-pagination-item-link-icon,
.content-lists h1 a,
.empty,
.ant-pagination-item-active,
.files .ant-list-item,
.files .ant-list-item a,
.frame h1,
.lecture-details a,
.lecture-details h1.ant-typography,
.lecture-details h2.ant-typography,
.lecture-details h3.ant-typography,
.lecture-details h4.ant-typography,
.lecture-details p,
.member-card .member-content h2,
.member-card .member-content h3,
.member-card .member-content p,
.no-results h4,
.play,
.recent h2,
.video-card h5,
.video-card:hover .play,
.video-content .list-thumbnail h5,
.video-content h2,
.video-content h3,
.video-content h4 {
  color: #fff;
}

@media only screen and (max-width: 1500px) {
  .content-lists {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .video-window iframe {
    height: 500px;
  }
  .video-box {
    height: auto;
    width: 1000px;
  }
  .frame {
    margin-left: 5px;
    margin-right: 5px;
    height: 600px;
    margin-bottom: 150px;
  }
  .photo-carousel img {
    height: 800px;
  }
  .recent {
    margin-top: -20px;
  }
  #thumbnail-box img {
    height: 100px;
  }
  #thumbnail-box:after {
    height: 120px;
  }
  .video-card img {
    min-height: 200px;
  }
}

@media only screen and (max-width: 992px) {
  .content-lists {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .frame h1 {
    padding: 380px 80px;
    position: relative;
    margin-top: 50px;
    margin-left: 0;
    margin-right: 20px;
    font: normal normal normal 80px/90px Abolition;
  }
  .video-window iframe {
    height: 400px;
  }
  .video-box {
    margin-top: 150px;
    margin-left: 50px;
    margin-right: 50px;
    width: auto;
  }
  .frame {
    margin: 0 auto 120px;
  }
  .video-card img {
    height: 250px;
  }
  .container {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 140px;
    width: auto;
    height: auto;
    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .video-window iframe {
    height: 300px;
  }
  .lecture-details h1.ant-typography {
    font: normal normal bold 26px/36px Open Sans;
  }
  .lecture-details h3.ant-typography {
    font: bold normal normal 14px/16px Open Sans;
  }
  .lecture-details h4.ant-typography {
    font: normal normal normal 14px/16px Open Sans;
  }
  .lecture-details h2.ant-typography {
    font: normal normal bold 20px/25px Open Sans;
  }
  .lecture-details a {
    font: normal normal normal 14px/16px Open Sans;
  }
  .container {
    margin-left: 50px;
    margin-right: 50px;
  }
  .container-content h1 {
    padding-bottom: 10px;
  }
  .video-card img {
    min-height: 350px;
  }
  .frame h1 {
    padding-left: 70px;
    font: normal normal normal 62px/80px Abolition;
  }
}

@media only screen and (max-width: 710px) {
  .lecture-details .details {
    flex-direction: column;
  }
  .lecture-details a {
    padding: 0 0 10px 0;
  }
  #date:after,
  #event:after {
    padding: 0;
    visibility: hidden;
  }
  #event:after {
    margin-left: -5px;
  }
}

@media only screen and (height: 1080px) and (width: 1920px) {
  .frame {
    height: 850px;
  }
  .frame #atc-logo {
    top: 420px;
    width: 400px;
  }
  .frame h1 {
    padding: 550px 0 0 100px;
    font: normal normal normal 120px/120px Abolition;
  }
  .photo-carousel img {
    height: 1060px;
  }
}

@media only screen and (height: 720px) and (width: 1280px) {
  .frame {
    height: 500px;
  }
  .frame #atc-logo {
    top: 240px;
    width: 280px;
  }
  .frame h1 {
    padding: 340px 0 0 80px;
    font: normal normal normal 80px/80px Abolition;
  }
  .photo-carousel img {
    height: 700px;
  }
}

@media only screen and (max-width: 576px) {
  .video-window iframe {
    height: 210px;
    padding-top: 20px;
  }
  .video-box {
    padding: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    background: linear-gradient(80deg, #0093e9 0, #0033a0 30%, #0b1117 100%)
      no-repeat padding-box;
  }
  .video-content {
    display: inline-table;
  }
  .frame h1 {
    font: normal normal normal 45px/60px Abolition;
  }
  .frame #atc-logo {
    top: 340px;
    width: 250px;
  }
  .container {
    background: linear-gradient(110deg, #0093e9 0, #0033a0 20%, #0b1117 100%)
      no-repeat padding-box;
  }
  .container-content h1 {
    padding-bottom: 0;
  }
  .container-content h5 {
    padding-bottom: 10px;
    font-size: 22px;
    float: left;
  }
  .container-content h4 {
    padding-top: 20px;
  }
  .video-card img {
    min-height: 250px;
  }
}

@media only screen and (max-width: 480px) {
  .frame #atc-logo {
    top: 270px;
    width: 200px;
  }
  .frame {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 80px;
    height: 450px;
  }
  .frame h1 {
    padding: 280px 0 0 50px;
    font: normal normal normal 50px/90px Abolition;
  }
  .photo-carousel img {
    height: 680px;
  }
  .recent {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 414px) {
  .frame #atc-logo {
    left: -20px;
  }
  .frame h1 {
    padding: 270px 0 0 40px;
    font: normal normal normal 40px/90px Abolition;
  }
}

@media only screen and (max-width: 375px) {
  .frame #atc-logo {
    width: 180px;
  }
  .frame h1 {
    font: normal normal normal 35px/90px Abolition;
  }
}

@media only screen and (max-width: 350px) {
  .video-window iframe {
    height: 180px;
  }
  .lecture-details h1.ant-typography {
    font: normal normal bold 20px/36px Open Sans;
  }
  .frame {
    height: 360px;
  }
  .frame #atc-logo {
    top: 190px;
    width: 140px;
  }
  .frame h1 {
    padding: 160px 0 0 30px;
    font: normal normal normal 35px/90px Abolition;
  }
  .photo-carousel img {
    height: 550px;
  }
  .container {
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
  }
  .container-content h1 {
    padding-bottom: 0;
    font: normal normal normal 30px/40px Abolition;
  }
  .recent {
    margin-top: 0;
  }
}

.__LECTURE_DETAIL__ .ant-tabs-tab {
  font-weight: bold;
  color: #fff;
}

.__LECTURE_DETAIL__ .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold !important;
  text-decoration: underline;
  color: #fff;
}
