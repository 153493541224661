.suggestion {
  width: auto;
  text-align: center;
  margin: 0 auto 60px;
  transition: all 0.5s;
  transition: transform 0.5s ease-in;
}

.suggestion h1 {
  text-align: center;
  font: normal normal normal 50px/50px Abolition;
  letter-spacing: 0.5px;
  color: #fff;
  opacity: 0.8;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 60px;
  transition: all 0.5s ease-in;
}

.suggestion h1:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #fff;
}

.hover-underline-animation:after {
  top: 70px;
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media only screen and (max-width: 992px) {
  .suggestion h1 {
    font: normal normal normal 40px/40px Abolition;
  }
  .no-results .suggestion .hover-underline-animation:after {
    top: 50px;
  }
}

@media only screen and (max-width: 685px) {
  .hover-underline-animation:after {
    top: 40px;
  }
  .no-results .suggestion .hover-underline-animation:after {
    top: 100px;
  }
}

@media only screen and (max-width: 470px) {
  .hover-underline-animation:after {
    top: 100px;
  }
  .no-results .suggestion .hover-underline-animation:after {
    top: 140px;
  }
  .no-results .suggestion {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 412px) {
  .no-results .suggestion h1 {
    font: normal normal normal 30px/30px Abolition;
  }
  .no-results .suggestion .hover-underline-animation:after {
    top: 100px;
  }
}
