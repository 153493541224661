#cern-toolbar {
  background-color: #222;
  font-family: "PT Sans", "Trebuchet MS", Tahoma, sans-serif;
  color: #999;
  font-size: 14px;
  line-height: 1;
  padding: 0 5%;
  height: 43px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
}

#cern-toolbar .ant-menu .ant-menu-item.signin,
#cern-toolbar h1 {
  position: relative;
}

#cern-toolbar a {
  color: #ccc;
  text-decoration: none;
  padding: 6px 8px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: inline-block;
}

#cern-toolbar a:hover {
  background-color: #000;
  color: #fff;
  border: 0;
}

#cern-toolbar .active a {
  background-color: #f6f6f6;
  color: #000;
  padding-bottom: 20px;
}

#cern-toolbar .active .cern-account {
  background-color: #fff;
}

#cern-toolbar h1 {
  float: left;
  line-height: 1;
  left: -8px;
  padding: 6px 0;
  letter-spacing: 0.08em;
  margin: 0;
  font: bold normal normal 14px/18px Open Sans;
}

#cern-toolbar h1 a,
#cern-toolbar h1 a:hover span {
  color: #fff;
}

#cern-toolbar h1 span {
  color: #999;
  letter-spacing: normal;
  padding: 0;
}

#cern-toolbar .ant-menu {
  float: right;
  list-style: none;
  position: relative;
  right: -8px;
  margin: 0;
  top: -32px;
}

#cern-toolbar .ant-menu-item {
  display: block;
  margin-left: 0.6em;
  padding: 6px 0;
}

#cern-toolbar div.item-list-length,
#cern-toolbar div.item-list-length div.item-list {
  display: inline !important;
  position: static !important;
  top: 0 !important;
  min-width: auto !important;
}

#cern-toolbar .ant-menu .ant-menu-item.signin div.item-list {
  display: none;
  position: absolute;
  background: rgba(30, 30, 30, 0.95);
  z-index: 9999999999999;
  top: 40px;
  min-width: 200px;
}

#cern-toolbar .ant-menu {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
}

#cern-toolbar .ant-menu-item {
  list-style: none;
  float: right !important;
  position: relative;
}

#cern-toolbar .ant-menu > .ant-menu-item::after {
  border: none !important;
}

@media only screen and (max-width: 768px) {
  #cern-toolbar a span {
    visibility: hidden;
  }
  #cern-toolbar .ant-menu-item a:not(.cern-multiple-mobile-signin),
  #cern-toolbar a.cern-single-mobile-signin:not(.cern-multiple-mobile-signin) {
    background-image: url(./toolbarsprite.png) !important;
    background-repeat: no-repeat !important;
    top: 20px;
    height: 40px !important;
    width: 40px !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    text-indent: -5000px !important;
    overflow: hidden !important;
    border-left: 1px solid #444 !important;
    box-sizing: border-box !important;
  }
  #cern-toolbar .cern-directory {
    background-position: 0 -40px;
  }
  #cern-toolbar .ant-menu {
    top: -21px;
  }
  #cern-toolbar .ant-menu .ant-menu-item a {
    height: 38px;
    background-position-x: 0;
  }
  #cern-toolbar .ant-menu .ant-menu-item.signin div.item-list {
    right: -70px;
  }
  #cern-toolbar .ant-menu-item {
    padding: 0;
    margin: 0;
  }
  #cern-toolbar {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  #cern-toolbar .ant-menu {
    right: -8px;
  }
}
