body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: rgb(0, 0, 0);
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

@font-face {
  font-family: "Abolition";
  src: local("Abolition"),
    url(./fonts/Abolition/Abolition-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Regular";
  src: local("Source_Sans_Pro"),
    url(./fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro Light";
  src: local("Source_Sans_Pro"),
    url(./fonts/Source_Sans_Pro/SourceSansPro-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  src: local("Open_Sans"),
    url(./fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Italic";
  src: local("Open_Sans"),
    url(./fonts/Open_Sans/OpenSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Light";
  src: local("Open_Sans"),
    url(./fonts/Open_Sans/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: local("Open_Sans"),
    url(./fonts/Open_Sans/OpenSans-Bold.ttf) format("truetype");
}
